import React, { useEffect, useState } from 'react';
import mitt from 'mitt';
import Cookies from 'js-cookie';
import Script from 'next/script';

import logger from 'services/logger';
import { isServer } from 'utils/is-server';
import { ONETRUST_CONSENT_COOKIE } from 'constants/cookies';

/**
 * All cookies are divided into categories based on their purpose.
 * Users can choose which categories they want to consent to.
 * [View all categories on the OneTrust dashboard](https://app-uk.onetrust.com/cookies/categorizations?tab=Categories)
 * (login required).
 */
export type CookieConsentCategory =
  | 'STRICTLY_NECESSARY'
  | 'PERFORMANCE'
  | 'FUNCTIONAL'
  | 'TARGETING'
  | 'SOCIAL_MEDIA';

/**
 * These ids are how OneTrust refers to the consent categories internally.
 */
export type OneTrustCookieConsentCategoryId =
  | 'C0001'
  | 'C0002'
  | 'C0003'
  | 'C0004'
  | 'C0005';

// The OneTrustSDK type is incomplete and should be extended if necessary.
type OneTrustSDK = {
  OnConsentChanged: (
    callback: (event: CustomEvent<OneTrustCookieConsentCategoryId[]>) => void,
  ) => void;
  ToggleInfoDisplay: () => void;
  changeLanguage: (locale: string) => void;
  getGeolocationData: () => { country: string; state: string };
  AllowAll: () => void;
};

declare global {
  interface Window {
    OneTrust?: OneTrustSDK;
    CookieConsentInit?: () => void;
  }
}

let isInitialized = false;

const COOKIE_CONSENT_INIT = 'CookieConsentInit';
const COOKIE_CONSENT_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];

// Mapping OneTrust's internal consent category ids to their human readable names.
export const CONSENT_CATEGORIES: Record<
  OneTrustCookieConsentCategoryId,
  CookieConsentCategory
> = {
  C0001: 'STRICTLY_NECESSARY',
  C0002: 'PERFORMANCE',
  C0003: 'FUNCTIONAL',
  C0004: 'TARGETING',
  C0005: 'SOCIAL_MEDIA',
};

const DEFAULT_CONSENT_CATEGORIES: CookieConsentCategory[] = [
  'STRICTLY_NECESSARY',
];

/**
 * A component to load and initialize the cookie consent script.
 * The script must be placed before any other script in the site in order to
 * ensure the consent banner is loaded before any other scripts have the chance
 * to load or set cookies.
 */
export function CookieConsentScript(): JSX.Element {
  return (
    <>
      <Script
        src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
        type="text/javascript"
        charSet="UTF-8"
        data-domain-script="49c6020d-fca3-46a0-ba49-c238084a6969-test"
      />
      <Script
        id="optanonWrapper"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          // The OneTrust SDK calls this function once it has loaded and on every consent change.
          // The `init` callback is added to the `window` at the bottom of this file.
          __html: `function OptanonWrapper() { 
            var init = window['${COOKIE_CONSENT_INIT}'];
            if (typeof init === 'function') {
              init();
            }
          }`,
        }}
      />
    </>
  );
}

export type CookieConsentEvents = {
  init: { categories: CookieConsentCategory[] };
  change: { categories: CookieConsentCategory[] };
  enable: boolean;
};

// This custom event emitter acts as a bridge between the OneTrust SDK and the
// exported consent API. This abstraction layer will make it easier to switch
// to a different provider in the future.
// Furthermore, it is necessary to prevent memory leaks when subscribing to
// events from React since the OneTrust SDK doesn't offer a way to unsubscribe.
export const cookieConsentEvents = mitt<CookieConsentEvents>();

/**
 * Returns an array of the cookie categories that the user has consented to
 * and updates if the user changes their consent.
 */
export function useCookieConsent(): {
  isEnabled: boolean;
  categories: CookieConsentCategory[];
} {
  const [isEnabled, setEnabled] = useState(true);
  const [categories, setCategories] = useState(DEFAULT_CONSENT_CATEGORIES);

  useEffect(() => {
    // The state needs to be updated *after* the initial render to prevent
    // hydration mismatches when server-side rendering.
    setEnabled(isConsentEnabled());
    setCategories(getActiveConsentCategories());
  }, []);

  useEffect(() => {
    const handleEnable = (event: CookieConsentEvents['enable']) => {
      setEnabled(event);
    };

    cookieConsentEvents.on('enable', handleEnable);

    return () => {
      cookieConsentEvents.off('enable', handleEnable);
    };
  }, []);

  useEffect(() => {
    const handleChange = (event: CookieConsentEvents['change']) => {
      setCategories(event.categories);
    };

    cookieConsentEvents.on('change', handleChange);

    return () => {
      cookieConsentEvents.off('change', handleChange);
    };
  }, []);

  return { isEnabled, categories };
}

/**
 * Attempts to open the cookie preference modal which also contains the link
 * to the cookie policy.
 */
export function showCookieConsentPreferences(): void {
  if (!window.OneTrust) {
    logger.warn('[OneTrust] The OneTrust SDK is not available.');
    return;
  }
  window.OneTrust.ToggleInfoDisplay();
}

/**
 * Reads and parses the OneTrust cookie that contains the currently active
 * cookie consent category ids and returns them as human readable names.
 */
export function getActiveConsentCategories(): CookieConsentCategory[] {
  const consentCookie = Cookies.get(ONETRUST_CONSENT_COOKIE);

  if (!consentCookie) {
    return DEFAULT_CONSENT_CATEGORIES;
  }

  try {
    // The OneTrust SDK serializes the cookie value as URL params.
    const parsedConsentCookie = new URLSearchParams(consentCookie);

    const consentGroups = parsedConsentCookie.get('groups');

    if (!consentGroups) {
      return DEFAULT_CONSENT_CATEGORIES;
    }

    // The consent categories are stored in the format 'C0001:1,C0002:0'
    // where '0' means consent NOT given and '1' means consent given.
    const consentCategories = consentGroups
      .split(',')
      .reduce((categories, categoryString) => {
        const [categoryId, categoryState] = categoryString.split(':') as [
          OneTrustCookieConsentCategoryId,
          '0' | '1',
        ];
        const category = CONSENT_CATEGORIES[categoryId];
        const isCategoryActive = categoryState === '1';

        if (category && isCategoryActive) {
          categories.push(category);
        }

        return categories;
      }, [] as CookieConsentCategory[]);
    return consentCategories;
  } catch (error) {
    logger.error(error as Error, '[OneTrust] Failed to parse consent cookie.');
    return DEFAULT_CONSENT_CATEGORIES;
  }
}

function isConsentEnabled(): boolean {
  if (!window.OneTrust) {
    logger.warn('[OneTrust] The OneTrust SDK is not available.');
    return true;
  }

  const geolocationData = window.OneTrust.getGeolocationData();

  if (geolocationData && geolocationData.country) {
    return COOKIE_CONSENT_COUNTRIES.includes(geolocationData.country);
  }

  return true;
}

function init() {
  // The OneTrust SDK calls the init callback multiple times.
  // This check makes sure it's only executed once.
  if (isInitialized) {
    return;
  }

  isInitialized = true;

  if (!window.OneTrust) {
    logger.warn(
      '[OneTrust] The OneTrust SDK is not available on init, this should never happen.',
    );
    return;
  }

  // FIXME: OneTrust does not support the German Luxembourg (de-LU) locale.
  // Their support suggested to use the Dutch Luxembourg locale (nl-LU)
  // to store the translations until they fix it.
  // Track solution progress here: https://ideas.onetrust.com/ideas/OT-I-11995
  // Once the issue has been solved we need to remove this logic.
  const locale = window.navigator.language;

  if (locale?.toLowerCase() === 'de-lu') {
    window.OneTrust.changeLanguage('nl-LU');
  }

  const initialCategories = getActiveConsentCategories();
  const isEnabled = isConsentEnabled();

  // OneTrust only pushes the `update` consent if the user clicks on the cookie banner. There are no banner on the Non-GDPR (non-cookie-consent) countries.
  // a manual Allowing all cookie consent for non-GDPR countries.
  // ref: https://my.onetrust.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7?topicId=0TO1Q000000ItVuWAK#UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7_section-idm231981224490148
  if (!isEnabled) {
    window.OneTrust.AllowAll();
  }

  cookieConsentEvents.emit('enable', isEnabled);
  cookieConsentEvents.emit('init', { categories: initialCategories });

  window.OneTrust.OnConsentChanged((event) => {
    const categoryIds = event.detail;
    const categories = categoryIds.map((id) => CONSENT_CATEGORIES[id]);

    cookieConsentEvents.emit('change', { categories });

    // No need to update the GTM consent here, OneTrust does it for us.
  });
}

if (!isServer) {
  if (window.OneTrust) {
    // The OneTrust SDK has already loaded and is available.
    init();
  } else {
    // The OneTrust SDK hasn't loaded yet and will call this callback when ready
    // (see the CookieConsentScript component above).
    window[COOKIE_CONSENT_INIT] = init;
  }
}
