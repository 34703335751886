import React, { createContext, useReducer, Dispatch, useMemo } from 'react';

import { getCache, setCache } from './Chat.utils';
import {
  ChatProviderAction,
  ChatProviderProps,
  ChatProviderSessionStorageKeys,
  ChatProviderState,
} from './Chat.types';

import { isServer } from 'src/utils/is-server';

export const ChatProviderContext =
  createContext<
    | { state: ChatProviderState; dispatch: Dispatch<ChatProviderAction> }
    | undefined
  >(undefined);

const chatProviderReducer = (
  state: ChatProviderState,
  action: ChatProviderAction,
): ChatProviderState => {
  switch (action.type) {
    case 'SET_MINIMIZED': {
      setCache(ChatProviderSessionStorageKeys.ChatMinimized, action.payload);
      return { ...state, isMinimized: action.payload };
    }
    case 'SET_ACTIVE': {
      setCache(ChatProviderSessionStorageKeys.ChatActive, action.payload);
      return { ...state, isActive: action.payload };
    }
    case 'SET_CONVERSATION_ID': {
      setCache(
        ChatProviderSessionStorageKeys.ChatConversationId,
        action.payload,
      );
      return { ...state, conversationId: action.payload };
    }
    default:
      return state;
  }
};

export default function ChatProvider({ children }: ChatProviderProps) {
  const [state, dispatch] = useReducer(chatProviderReducer, {
    isActive:
      (!isServer &&
        getCache<boolean>(ChatProviderSessionStorageKeys.ChatActive)) ??
      undefined,
    isMinimized:
      (!isServer &&
        getCache<boolean>(ChatProviderSessionStorageKeys.ChatMinimized)) ??
      undefined,
    conversationId:
      (!isServer &&
        getCache<string>(ChatProviderSessionStorageKeys.ChatConversationId)) ??
      undefined,
  });
  const memoizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <ChatProviderContext.Provider value={memoizedValue}>
      {children}
    </ChatProviderContext.Provider>
  );
}
