'use client';

import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { clsx } from '@sumup/circuit-ui';
import { invert } from 'lodash';
import Cookies from 'js-cookie';

import styles from './ChatIframe.module.css';
import { ChatIframeMessageEventDataType } from './ChatIframe.types';

import { ChatProviderContext } from 'src/providers/Chat/Chat';
import {
  CONSENT_CATEGORIES,
  useCookieConsent,
} from 'src/components/CookieConsent/public';

const ONE_TRUST_CATEGORIES = invert(CONSENT_CATEGORIES);

const ChatIframe = ({ locale }: { locale: string }) => {
  // Context
  const {
    dispatch,
    state: { isMinimized, conversationId },
  } = useContext(ChatProviderContext);

  // Configurations
  const iframeSrc = new URL(process.env.NEXT_PUBLIC_CHAT_URL);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  // Post Message Logic (Send)
  const sendMessage = (payload: any) => {
    iframeRef.current?.contentWindow?.postMessage(payload, iframeSrc.href);
  };

  // Resize Logic
  const [size, setSize] = useState({ width: '700px', height: '720px' });
  const onResizeData = (width: string | number, height: string | number) => {
    setSize({
      width: typeof width === 'number' ? `${width}px` : width,
      height: typeof height === 'number' ? `${height}px` : height,
    });
  };

  const onClose = () => {
    dispatch({ type: 'SET_ACTIVE', payload: false });
    dispatch({ type: 'SET_MINIMIZED', payload: false });
    dispatch({ type: 'SET_CONVERSATION_ID', payload: '' });
  };

  // Minimize logic
  const isAIAssistantMinimized = false;
  const onMinimize = (minimizedStatus: boolean) => {
    dispatch({ type: 'SET_MINIMIZED', payload: minimizedStatus });
  };

  // Cookie consent logic
  const { categories } = useCookieConsent();
  useEffect(() => {
    if (iframeLoaded) {
      const oneTrustCategoriesIds = categories.map(
        (category) => ONE_TRUST_CATEGORIES[category],
      );
      sendMessage({
        type: 'consentCategories',
        consentCategories: oneTrustCategoriesIds,
      });
    }
  }, [categories, iframeLoaded]);

  // Handshake logic
  const trackingId = Cookies.get('optimizely_experiment_user');
  const hasAdminPermission = false;
  const connection = 'unauthenticated';
  const applicationId = 'support-centre';
  const entryPoint = 'support_centre';
  const sendHandshake = useCallback(() => {
    // Websocket Token
    sendMessage({
      type: 'handshake',
      connection,
      conversationId,
      locale,
      applicationId,
      entryPoint,
      trackingId,
      isFullScreen: window.innerWidth < 768,
      isMinimized,
      isMobile: window.innerWidth < 768,
      isConversationManagementEnabled: true,
    });
  }, [locale, trackingId, hasAdminPermission, isMinimized, conversationId]);

  useEffect(() => {
    if (iframeLoaded) {
      sendHandshake();
    }
  }, [iframeLoaded]);

  // Navigation logic
  const handleNavigate = (url: string) => {
    window.open(url, '_blank');
  };

  // Conversation ID logic
  const handleConversationId = (newConversationId: string) => {
    dispatch({ type: 'SET_CONVERSATION_ID', payload: newConversationId });
  };

  // Post Message Logic (Receive)
  const parseMessage = (eventData: ChatIframeMessageEventDataType) => {
    switch (eventData.type) {
      case 'onClose':
        onClose();
        break;
      case 'onMinimize':
        onMinimize(eventData.minimized);
        break;
      case 'resizeData':
        onResizeData(eventData.resizeData.width, eventData.resizeData.height);
        break;
      case 'navigateData':
        handleNavigate(eventData.navigateData.url);
        break;
      case 'conversationId':
        handleConversationId(eventData.conversationId);
        break;
      default:
        break;
    }
  };

  const onMessage = (event: MessageEvent) => {
    if (event.origin !== iframeSrc.origin) {
      return;
    }
    // We are casting based on the contract
    // https://www.notion.so/RFC-00201-chat-sumup-com-19060cc473228070994ac0f4fd82e630
    parseMessage(event.data as ChatIframeMessageEventDataType);
  };

  // Iframe logic
  const onIframeLoad = () => {
    window.addEventListener('message', onMessage);
    setIframeLoaded(true);
  };

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = iframeSrc.href;
    }
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <iframe
      title="Chat"
      ref={iframeRef}
      style={{
        width: size.width,
        height: size.height,
      }}
      className={clsx(
        styles.iframe,
        isAIAssistantMinimized && styles.minimized,
      )}
      onLoad={() => {
        void onIframeLoad();
      }}
    />
  );
};

export default ChatIframe;
