'use client';

import React, { ReactNode, useEffect, useState } from 'react';

export interface Props {
  children: ReactNode;
}
/**
 * Renders children only on the client side.
 */
export default function ClientOnly({ children }: Props): JSX.Element {
  const [onClient, setOnClient] = useState(false);
  useEffect(() => {
    setOnClient(true);
  }, []);
  if (!onClient) {
    return null;
  }
  return <>{children}</>;
}
