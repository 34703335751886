import { ReactNode } from 'react';

export interface ChatProviderState {
  isActive: boolean;
  isMinimized: boolean;
  conversationId: string;
}

export enum ChatProviderSessionStorageKeys {
  ChatMinimized = 'chat-minimized',
  ChatActive = 'chat-active',
  ChatConversationId = 'chat-conversation-id',
}

export type ChatProviderAction =
  | { type: 'SET_MINIMIZED'; payload: boolean }
  | { type: 'SET_ACTIVE'; payload: boolean }
  | { type: 'SET_CONVERSATION_ID'; payload: string };

export type ChatProviderProps = {
  children: ReactNode;
};
