'use client';

import { useContext, useEffect, useState } from 'react';
import { Button } from '@sumup/circuit-ui';
import { LiveChat } from '@sumup/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import styles from './Chat.module.css';
import ChatIframe from './ChatIframe/ChatIframe';

import { ChatProviderContext } from 'src/providers/Chat/Chat';

const Chat = ({ locale }: { locale: string }) => {
  const { t } = useTranslation();
  const {
    dispatch,
    state: { isActive },
  } = useContext(ChatProviderContext);
  const router = useRouter();
  const { chat } = router.query;
  const [renderChildren, setRenderChildren] = useState(false);

  const activateChat = () => {
    dispatch({ type: 'SET_MINIMIZED', payload: false });
    dispatch({ type: 'SET_ACTIVE', payload: true });
  };

  useEffect(() => {
    // Activate chat directly if 'chat' query param is set to 'true'
    if (chat === 'true') {
      activateChat();
    }
    setRenderChildren(true);
  }, [chat]);

  // Do not render children until the query param is checked
  if (!renderChildren) {
    return null;
  }

  return isActive ? (
    <ChatIframe locale={locale} />
  ) : (
    <div className={styles.buttonContainer}>
      <Button variant={'primary'} icon={LiveChat} onClick={activateChat}>
        {t('chat.start_button')}
      </Button>
    </div>
  );
};

export default Chat;
